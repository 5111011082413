import { createRouter, createWebHistory } from "vue-router";

const routes = [

  {
    path: "/",
    component: () => import("../components/layouts/plain.layout.vue"),
    children: [

      {
        path: "",
        name: "home",
        redirect: { name: "portal-signin" },
      },

      {
        name: "portal-signin",
        path: "/portal/signin",
        component: () => import("../pages/portal/signin.page.vue"),
      },
      {
        name: 'portal-forgot-password',
        path: '/portal/forgot-password',
        component: () => import('../pages/portal/forgot-password.page.vue'),
      },

      {
        name: 'portal-reset-password',
        path: '/portal/reset-password',
        component: () => import('../pages/portal/reset-password.page.vue'),
      },
    ],
  },


  //Admin
  {
    path: "/admin",
    name: "admin",
    component: () => import("../components/layouts/admin.layout.vue"),
    children: [
      //Dashboard
      {
        path: "",
        name: "admin-home",
        redirect: { name: "admin-dashboard" },
      },
      {
        path: "/admin/dashboard",
        name: "admin-dashboard",
        component: () => import("../pages/admin/dashboard/index.page.vue"),
      },
      //Users
      {
        path: "/admin/users",
        name: "admin-users",
        component: () => import("../pages/admin/users/index.page.vue"),
      },
      {
        path: "/admin/users/manage/:id",
        name: "admin-manage-user",
        component: () => import("../pages/admin/users/manage.page.vue"),
      },

      {
        path: "/admin/catalogue",
        name: "admin-catalogue",
        component: () => import("../pages/admin/catalogue/index.page.vue"),
      },
      {
        path: "/admin/catalogue/manage/:id",
        name: "admin-manage-catalogue",
        component: () => import("../pages/admin/catalogue/manage.page.vue"),
      },


      {
        path: "/admin/vmmc",
        name: "admin-vmmc",
        component: () => import("../pages/admin/vmmc/index.page.vue"),
      },

      {
        path: "/admin/relocations",
        name: "admin-relocations",
        component: () => import("../pages/admin/relocations/index.page.vue"),
      },

      {
        path: "/admin/vmmc/manage/:id",
        name: "admin-manage-vmmc",
        component: () => import("../pages/admin/vmmc/manage.page.vue"),
      },



      {
        path: "/admin/stocks",
        name: "admin-stocks",
        component: () => import("../pages/admin/stocks/index.page.vue"),
      },
      {
        path: "/admin/stocks/manage/:id",
        name: "admin-manage-stocks",
        component: () => import("../pages/admin/stocks/manage.page.vue"),
      },
      {
        path: "/admin/catalogue/edit/:id",
        name: "admin-edit-catalogue",
        component: () => import("../pages/admin/catalogue/edit.page.vue"),
      },

      {
        path: "/admin/stats",
        name: "admin-stats",
        component: () => import("../pages/admin/stats/index.page.vue"),
      },


      {
        path: "/admin/stats/details",
        name: "admin-stats-details",
        component: () => import("../pages/admin/stats/stats.details.vue"),
      },


      {
        path: "/admin/media",
        name: "admin-media",
        component: () => import("../pages/admin/media/index.page.vue"),
      },
      {
        path: "/admin/media/manage/:id",
        name: "admin-manage-media",
        component: () => import("../pages/admin/media/manage.page.vue"),
      },



      {
        path: "/admin/facilities",
        name: "admin-facilities",
        component: () => import("../pages/admin/facilities/index.page.vue"),
      },
      {
        path: "/admin/facilities/manage/:id",
        name: "admin-facilities-media",
        component: () => import("../pages/admin/facilities/manage.page.vue"),
      },



      {
        path: "/admin/communications",
        name: "admin-communications",
        component: () => import("../pages/admin/communications/index.page.vue"),
      },
      {
        path: "/admin/communications/manage/:id",
        name: "admin-manage-communications",
        component: () => import("../pages/admin/communications/manage.page.vue"),
      },


      {
        path: "/admin/feedback",
        name: "admin-feedback",
        component: () => import("../pages/admin/feedback/index.page.vue"),
      },


      {
        path: "/admin/media/edit/:id",
        name: "admin-edit-media",
        component: () => import("../pages/admin/media/edit.page.vue"),
      },


      {
        path: "/admin/catalogue/chapter/manage/:id",
        name: "admin-manage-chapter",
        component: () => import("../components/pages/catalogue/chapter-details.component.vue"),
      },



      {
        path: "/admin/catalogue/section/manage/:id",
        name: "admin-manage-section",
        component: () => import("../components/pages/catalogue/section-details.component.vue"),
      },


      {
        path: "/admin/catalogue/subsection/manage/:id",
        name: "admin-manage-subsection",
        component: () => import("../components/pages/catalogue/subsection-details.component.vue"),
      },


      {
        path: "/admin/catalogue/paragraph/manage/:id",
        name: "admin-manage-paragraph",
        component: () => import("../components/pages/catalogue/paragraph-details.component.vue"),

      },



      {
        path: "/admin/help",
        name: "help",
        component: () => import("../components/layouts/help-center.vue"),
      },
    ],
    beforeEnter: (to, from, next) => {
      let role = JSON.parse(sessionStorage.getItem("RLE"));
      if (role != null && typeof role.name != "undefined") {
        if (role.name == "admin") {
          next();
        } else {
          next({ name: "portal-signin" });
        }
      } else {
        next({ name: "portal-signin" });
      }
    },
  },


  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("../pages/Errors/404.error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
